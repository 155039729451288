import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25044d0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bar-filter" }
const _hoisted_2 = { class: "section-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!

  return (_openBlock(), _createBlock(_component_AfterLoginPage, { class: "home-admin-wiz" }, {
    "top-part": _withCtx(() => [
      _createVNode(_component_BreadScrum, { paths: _ctx.breadScrumLinks }, null, 8, ["paths"])
    ]),
    "left-top-part": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.currentYear + _ctx.$t("wiz.thisYearCostReport")), 1),
        _createVNode(_component_Dropdown, {
          options: _ctx.years,
          "is-small-dropdown": "",
          modelValue: _ctx.currentYear,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentYear) = $event))
        }, null, 8, ["options", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("wiz.tableTitlePaymentNotice")), 1)
    ]),
    "left-bottom-part": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (entry, i) => {
        return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
          key: i,
          "head-icon": require('icons/invoice.svg'),
          actions: _ctx.actionDropdown(i),
          "has-divider": ""
        }, {
          "title-primary": _withCtx(() => [
            _createTextVNode(_toDisplayString(entry.name), 1)
          ]),
          "title-secondary": _withCtx(() => [
            _createTextVNode(_toDisplayString(`公開日 ${_ctx.formatDate(entry.createdAt, "YYYY/MM/DD")}`), 1)
          ]),
          _: 2
        }, 1032, ["head-icon", "actions"]))
      }), 128))
    ]),
    _: 1
  }))
}